// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cloud-build-devops-tools-js": () => import("./../../../src/pages/cloud-build-devops-tools.js" /* webpackChunkName: "component---src-pages-cloud-build-devops-tools-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-1-js": () => import("./../../../src/pages/demo1.js" /* webpackChunkName: "component---src-pages-demo-1-js" */),
  "component---src-pages-edge-cloud-js": () => import("./../../../src/pages/edge-cloud.js" /* webpackChunkName: "component---src-pages-edge-cloud-js" */),
  "component---src-pages-education-consultation-js": () => import("./../../../src/pages/education-consultation.js" /* webpackChunkName: "component---src-pages-education-consultation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-cloud-js": () => import("./../../../src/pages/it-cloud.js" /* webpackChunkName: "component---src-pages-it-cloud-js" */),
  "component---src-pages-news-archived-js": () => import("./../../../src/pages/news-archived.js" /* webpackChunkName: "component---src-pages-news-archived-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-nfvi-certification-js": () => import("./../../../src/pages/nfvi-certification.js" /* webpackChunkName: "component---src-pages-nfvi-certification-js" */),
  "component---src-pages-our-customers-js": () => import("./../../../src/pages/our-customers.js" /* webpackChunkName: "component---src-pages-our-customers-js" */),
  "component---src-pages-our-mission-js": () => import("./../../../src/pages/our-mission.js" /* webpackChunkName: "component---src-pages-our-mission-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-publication-js": () => import("./../../../src/pages/publication.js" /* webpackChunkName: "component---src-pages-publication-js" */),
  "component---src-pages-telco-cloud-js": () => import("./../../../src/pages/telco-cloud.js" /* webpackChunkName: "component---src-pages-telco-cloud-js" */),
  "component---src-pages-touchstone-js": () => import("./../../../src/pages/touchstone.js" /* webpackChunkName: "component---src-pages-touchstone-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */)
}

